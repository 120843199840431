// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DashboardCss from "../../../../styleguide/dashboard/components/views/common/DashboardCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardTsdNavbar from "../common/navbar/DashboardTsdNavbar.res.js";
import * as DashboardTsdEditForm from "./components/DashboardTsdEditForm.res.js";

function DashboardTsdEdit$default(props) {
  var tsd = props.tsd;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(DashboardTsdNavbar.make, {
                      currentLink: "Edit",
                      tsdId: Caml_option.some(tsd.id)
                    }),
                JsxRuntime.jsx(Container.make, {
                      className: DashboardCss.container,
                      children: JsxRuntime.jsx(DashboardTsdEditForm.make, {
                            tsd: tsd
                          })
                    })
              ],
              className: DashboardCss.pageContainer
            });
}

var Css;

var $$default = DashboardTsdEdit$default;

export {
  Css ,
  $$default as default,
}
/* Container Not a pure module */
