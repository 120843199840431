// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H1 from "../../../../../styleguide/components/Heading/H1.res.js";
import * as Hr from "../../../../../styleguide/components/Hr/Hr.res.js";
import * as Url from "../../../../../utils/Url.res.js";
import * as Label from "../../../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Toggle from "../../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as Api_Tsd from "../../../../../api/tsds/Api_Tsd.res.js";
import * as $$Promise from "../../../../../bindings/Promise.res.js";
import * as IconView from "../../../../../styleguide/icons/IconView.res.js";
import * as Textarea from "../../../../../styleguide/forms/Textarea/Textarea.res.js";
import * as Uploader from "../../../../../styleguide/forms/Uploader/Uploader.res.js";
import * as Formality from "re-formality/src/Formality.res.js";
import * as TextField from "../../../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Routes_Tsd from "../../../../../routes/common/Routes_Tsd.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../../../loggers/SentryLogger.res.js";
import * as DashboardForm from "../../../../../styleguide/dashboard/components/forms/DashboardForm.res.js";
import * as ReactDatepicker from "../../../../../bindings/ReactDatepicker.res.js";
import * as ResponseMessage from "../../../../../styleguide/forms/ResponseMessage/ResponseMessage.res.js";
import * as ActiveModelError from "../../../../../models/ActiveModelError.res.js";
import * as DashboardFormCss from "../../../../../styleguide/dashboard/components/forms/DashboardFormCss.res.js";
import * as ResourceDocument from "../../../../resources/resource-new/ResourceDocument.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardDocumentPdf from "../../../../../styleguide/dashboard/components/documents/pdf/DashboardDocumentPdf.res.js";
import * as IconAdditionalUpload from "../../../../../styleguide/icons/IconAdditionalUpload.res.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.res.js";

function reducer(state, action) {
  if (typeof action !== "object") {
    if (action === "SetUsernameVisible") {
      return {
              message: state.message,
              document: state.document,
              contractResource: state.contractResource,
              messages: state.messages,
              usernameVisible: !state.usernameVisible,
              passwordVisible: state.passwordVisible
            };
    } else {
      return {
              message: state.message,
              document: state.document,
              contractResource: state.contractResource,
              messages: state.messages,
              usernameVisible: state.usernameVisible,
              passwordVisible: !state.passwordVisible
            };
    }
  }
  switch (action.TAG) {
    case "SetMessage" :
        return {
                message: action._0,
                document: state.document,
                contractResource: state.contractResource,
                messages: state.messages,
                usernameVisible: state.usernameVisible,
                passwordVisible: state.passwordVisible
              };
    case "SetDocument" :
        return {
                message: state.message,
                document: action._0,
                contractResource: state.contractResource,
                messages: state.messages,
                usernameVisible: state.usernameVisible,
                passwordVisible: state.passwordVisible
              };
    case "SetMessages" :
        return {
                message: state.message,
                document: state.document,
                contractResource: state.contractResource,
                messages: action._0,
                usernameVisible: state.usernameVisible,
                passwordVisible: state.passwordVisible
              };
    case "SetContractResource" :
        return {
                message: state.message,
                document: state.document,
                contractResource: action._0,
                messages: state.messages,
                usernameVisible: state.usernameVisible,
                passwordVisible: state.passwordVisible
              };
    
  }
}

var validators_contractStart = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = input.contractStart;
      if (x !== undefined) {
        return {
                TAG: "Ok",
                _0: Caml_option.valFromOption(x).toString()
              };
      } else {
        return {
                TAG: "Error",
                _0: "Contract Start Date is required"
              };
      }
    })
};

var validators_name = {
  strategy: "OnFirstSuccessOrFirstBlur",
  validate: (function (input) {
      var x = input.name;
      if (x.trim() === "") {
        return {
                TAG: "Error",
                _0: "Name is required"
              };
      } else {
        return {
                TAG: "Ok",
                _0: input.name
              };
      }
    })
};

var validators = {
  encryptedPassword: undefined,
  username: undefined,
  adminUrl: undefined,
  homeUrl: undefined,
  contractStart: validators_contractStart,
  description: undefined,
  active: undefined,
  name: validators_name
};

function initialFieldsStatuses(_input) {
  return {
          encryptedPassword: "Pristine",
          username: "Pristine",
          adminUrl: "Pristine",
          homeUrl: "Pristine",
          contractStart: "Pristine",
          description: "Pristine",
          active: "Pristine",
          name: "Pristine"
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: "Editing",
          submissionStatus: "NeverSubmitted"
        };
}

function validateForm(input, validators, fieldsStatuses) {
  var match_0 = {
    TAG: "Ok",
    _0: input.encryptedPassword
  };
  var match_0$1 = {
    TAG: "Ok",
    _0: input.username
  };
  var match_0$2 = {
    TAG: "Ok",
    _0: input.adminUrl
  };
  var match_0$3 = {
    TAG: "Ok",
    _0: input.homeUrl
  };
  var match = fieldsStatuses.contractStart;
  var tmp;
  tmp = typeof match !== "object" ? validators.contractStart.validate(input) : match._0;
  var match_0$4 = {
    TAG: "Ok",
    _0: input.description
  };
  var match_0$5 = {
    TAG: "Ok",
    _0: input.active
  };
  var match$1 = fieldsStatuses.name;
  var tmp$1;
  tmp$1 = typeof match$1 !== "object" ? validators.name.validate(input) : match$1._0;
  var encryptedPasswordResult = match_0;
  var encryptedPasswordResult$1;
  if (encryptedPasswordResult.TAG === "Ok") {
    var usernameResult = match_0$1;
    if (usernameResult.TAG === "Ok") {
      var adminUrlResult = match_0$2;
      if (adminUrlResult.TAG === "Ok") {
        var homeUrlResult = match_0$3;
        if (homeUrlResult.TAG === "Ok") {
          var contractStartResult = tmp;
          if (contractStartResult.TAG === "Ok") {
            var descriptionResult = match_0$4;
            if (descriptionResult.TAG === "Ok") {
              var activeResult = match_0$5;
              if (activeResult.TAG === "Ok") {
                var nameResult = tmp$1;
                if (nameResult.TAG === "Ok") {
                  return {
                          TAG: "Valid",
                          output: {
                            name: nameResult._0,
                            active: activeResult._0,
                            description: descriptionResult._0,
                            contractStart: contractStartResult._0,
                            homeUrl: homeUrlResult._0,
                            adminUrl: adminUrlResult._0,
                            username: usernameResult._0,
                            encryptedPassword: encryptedPasswordResult._0
                          },
                          fieldsStatuses: {
                            encryptedPassword: {
                              TAG: "Dirty",
                              _0: encryptedPasswordResult,
                              _1: "Hidden"
                            },
                            username: {
                              TAG: "Dirty",
                              _0: usernameResult,
                              _1: "Hidden"
                            },
                            adminUrl: {
                              TAG: "Dirty",
                              _0: adminUrlResult,
                              _1: "Hidden"
                            },
                            homeUrl: {
                              TAG: "Dirty",
                              _0: homeUrlResult,
                              _1: "Hidden"
                            },
                            contractStart: {
                              TAG: "Dirty",
                              _0: contractStartResult,
                              _1: "Shown"
                            },
                            description: {
                              TAG: "Dirty",
                              _0: descriptionResult,
                              _1: "Hidden"
                            },
                            active: {
                              TAG: "Dirty",
                              _0: activeResult,
                              _1: "Hidden"
                            },
                            name: {
                              TAG: "Dirty",
                              _0: nameResult,
                              _1: "Shown"
                            }
                          },
                          collectionsStatuses: undefined
                        };
                }
                encryptedPasswordResult$1 = encryptedPasswordResult;
              } else {
                encryptedPasswordResult$1 = encryptedPasswordResult;
              }
            } else {
              encryptedPasswordResult$1 = encryptedPasswordResult;
            }
          } else {
            encryptedPasswordResult$1 = encryptedPasswordResult;
          }
        } else {
          encryptedPasswordResult$1 = encryptedPasswordResult;
        }
      } else {
        encryptedPasswordResult$1 = encryptedPasswordResult;
      }
    } else {
      encryptedPasswordResult$1 = encryptedPasswordResult;
    }
  } else {
    encryptedPasswordResult$1 = encryptedPasswordResult;
  }
  return {
          TAG: "Invalid",
          fieldsStatuses: {
            encryptedPassword: {
              TAG: "Dirty",
              _0: encryptedPasswordResult$1,
              _1: "Hidden"
            },
            username: {
              TAG: "Dirty",
              _0: match_0$1,
              _1: "Hidden"
            },
            adminUrl: {
              TAG: "Dirty",
              _0: match_0$2,
              _1: "Hidden"
            },
            homeUrl: {
              TAG: "Dirty",
              _0: match_0$3,
              _1: "Hidden"
            },
            contractStart: {
              TAG: "Dirty",
              _0: tmp,
              _1: "Shown"
            },
            description: {
              TAG: "Dirty",
              _0: match_0$4,
              _1: "Hidden"
            },
            active: {
              TAG: "Dirty",
              _0: match_0$5,
              _1: "Hidden"
            },
            name: {
              TAG: "Dirty",
              _0: tmp$1,
              _1: "Shown"
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "BlurEncryptedPasswordField" :
                  var result = Formality.validateFieldOnBlurWithoutValidator(state.input.encryptedPassword, state.fieldsStatuses.encryptedPassword, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  encryptedPassword: status,
                                  username: init.username,
                                  adminUrl: init.adminUrl,
                                  homeUrl: init.homeUrl,
                                  contractStart: init.contractStart,
                                  description: init.description,
                                  active: init.active,
                                  name: init.name
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurUsernameField" :
                  var result$1 = Formality.validateFieldOnBlurWithoutValidator(state.input.username, state.fieldsStatuses.username, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  encryptedPassword: init.encryptedPassword,
                                  username: status,
                                  adminUrl: init.adminUrl,
                                  homeUrl: init.homeUrl,
                                  contractStart: init.contractStart,
                                  description: init.description,
                                  active: init.active,
                                  name: init.name
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurAdminUrlField" :
                  var result$2 = Formality.validateFieldOnBlurWithoutValidator(state.input.adminUrl, state.fieldsStatuses.adminUrl, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  encryptedPassword: init.encryptedPassword,
                                  username: init.username,
                                  adminUrl: status,
                                  homeUrl: init.homeUrl,
                                  contractStart: init.contractStart,
                                  description: init.description,
                                  active: init.active,
                                  name: init.name
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurHomeUrlField" :
                  var result$3 = Formality.validateFieldOnBlurWithoutValidator(state.input.homeUrl, state.fieldsStatuses.homeUrl, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  encryptedPassword: init.encryptedPassword,
                                  username: init.username,
                                  adminUrl: init.adminUrl,
                                  homeUrl: status,
                                  contractStart: init.contractStart,
                                  description: init.description,
                                  active: init.active,
                                  name: init.name
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurContractStartField" :
                  var result$4 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.contractStart, validators_contractStart, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  encryptedPassword: init.encryptedPassword,
                                  username: init.username,
                                  adminUrl: init.adminUrl,
                                  homeUrl: init.homeUrl,
                                  contractStart: status,
                                  description: init.description,
                                  active: init.active,
                                  name: init.name
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurDescriptionField" :
                  var result$5 = Formality.validateFieldOnBlurWithoutValidator(state.input.description, state.fieldsStatuses.description, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  encryptedPassword: init.encryptedPassword,
                                  username: init.username,
                                  adminUrl: init.adminUrl,
                                  homeUrl: init.homeUrl,
                                  contractStart: init.contractStart,
                                  description: status,
                                  active: init.active,
                                  name: init.name
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurActiveField" :
                  var result$6 = Formality.validateFieldOnBlurWithoutValidator(state.input.active, state.fieldsStatuses.active, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  encryptedPassword: init.encryptedPassword,
                                  username: init.username,
                                  adminUrl: init.adminUrl,
                                  homeUrl: init.homeUrl,
                                  contractStart: init.contractStart,
                                  description: init.description,
                                  active: status,
                                  name: init.name
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "BlurNameField" :
                  var result$7 = Formality.validateFieldOnBlurWithValidator(state.input, state.fieldsStatuses.name, validators_name, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  encryptedPassword: init.encryptedPassword,
                                  username: init.username,
                                  adminUrl: init.adminUrl,
                                  homeUrl: init.homeUrl,
                                  contractStart: init.contractStart,
                                  description: init.description,
                                  active: init.active,
                                  name: status
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "Submit" :
                  var match = state.formStatus;
                  if (typeof match === "object" && match.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses);
                  if (match$1.TAG !== "Valid") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: "AttemptedToSubmit"
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error !== "object" || error.TAG !== "SubmissionFailed" ? undefined : Caml_option.some(error._0);
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: "Submitting",
                              _0: tmp
                            },
                            submissionStatus: "AttemptedToSubmit"
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              onSubmit(output, {
                                    notifyOnSuccess: (function (input) {
                                        dispatch({
                                              TAG: "SetSubmittedStatus",
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        dispatch({
                                              TAG: "SetSubmissionFailedStatus",
                                              _0: error
                                            });
                                      }),
                                    reset: (function () {
                                        dispatch("Reset");
                                      }),
                                    dismissSubmissionResult: (function () {
                                        dispatch("DismissSubmissionResult");
                                      })
                                  });
                            })
                        };
                  break;
              case "DismissSubmissionError" :
                  var match$2 = state.formStatus;
                  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Editing",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "DismissSubmissionResult" :
                  var match$3 = state.formStatus;
                  if (typeof match$3 !== "object") {
                    if (match$3 === "Editing") {
                      return "NoUpdate";
                    }
                    
                  } else if (match$3.TAG === "Submitting") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: "Editing",
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "Update",
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "UpdateEncryptedPasswordField" :
                  var nextInput = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput.encryptedPassword, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            encryptedPassword: status,
                                            username: init.username,
                                            adminUrl: init.adminUrl,
                                            homeUrl: init.homeUrl,
                                            contractStart: init.contractStart,
                                            description: init.description,
                                            active: init.active,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateUsernameField" :
                  var nextInput$1 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$1.username, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            encryptedPassword: init.encryptedPassword,
                                            username: status,
                                            adminUrl: init.adminUrl,
                                            homeUrl: init.homeUrl,
                                            contractStart: init.contractStart,
                                            description: init.description,
                                            active: init.active,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateAdminUrlField" :
                  var nextInput$2 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$2.adminUrl, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            encryptedPassword: init.encryptedPassword,
                                            username: init.username,
                                            adminUrl: status,
                                            homeUrl: init.homeUrl,
                                            contractStart: init.contractStart,
                                            description: init.description,
                                            active: init.active,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateHomeUrlField" :
                  var nextInput$3 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$3.homeUrl, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            encryptedPassword: init.encryptedPassword,
                                            username: init.username,
                                            adminUrl: init.adminUrl,
                                            homeUrl: status,
                                            contractStart: init.contractStart,
                                            description: init.description,
                                            active: init.active,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateContractStartField" :
                  var nextInput$4 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$4, state.fieldsStatuses.contractStart, state.submissionStatus, validators_contractStart, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            encryptedPassword: init.encryptedPassword,
                                            username: init.username,
                                            adminUrl: init.adminUrl,
                                            homeUrl: init.homeUrl,
                                            contractStart: status,
                                            description: init.description,
                                            active: init.active,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateDescriptionField" :
                  var nextInput$5 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$5.description, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            encryptedPassword: init.encryptedPassword,
                                            username: init.username,
                                            adminUrl: init.adminUrl,
                                            homeUrl: init.homeUrl,
                                            contractStart: init.contractStart,
                                            description: status,
                                            active: init.active,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateActiveField" :
                  var nextInput$6 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithoutValidator(nextInput$6.active, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            encryptedPassword: init.encryptedPassword,
                                            username: init.username,
                                            adminUrl: init.adminUrl,
                                            homeUrl: init.homeUrl,
                                            contractStart: init.contractStart,
                                            description: init.description,
                                            active: status,
                                            name: init.name
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "UpdateNameField" :
                  var nextInput$7 = action._0(state.input);
                  return {
                          TAG: "Update",
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidator(nextInput$7, state.fieldsStatuses.name, state.submissionStatus, validators_name, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            encryptedPassword: init.encryptedPassword,
                                            username: init.username,
                                            adminUrl: init.adminUrl,
                                            homeUrl: init.homeUrl,
                                            contractStart: init.contractStart,
                                            description: init.description,
                                            active: init.active,
                                            name: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "SetSubmittedStatus" :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: "Submitted",
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case "SetSubmissionFailedStatus" :
                  return {
                          TAG: "Update",
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: "SubmissionFailed",
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case "MapSubmissionError" :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 !== "object") {
                    return "NoUpdate";
                  }
                  if (error$1.TAG !== "Submitting") {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "SubmissionFailed",
                                _0: map(error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: "Update",
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: "Submitting",
                                _0: Caml_option.some(map(Caml_option.valFromOption(error$2)))
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return "NoUpdate";
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 !== "object" || match$1.TAG !== "Submitting" ? false : true;
  return {
          updateEncryptedPassword: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateEncryptedPasswordField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateUsername: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateUsernameField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateAdminUrl: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateAdminUrlField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateHomeUrl: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateHomeUrlField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateContractStart: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateContractStartField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateDescription: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateDescriptionField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateActive: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateActiveField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          updateName: (function (nextInputFn, nextValue) {
              dispatch({
                    TAG: "UpdateNameField",
                    _0: (function (__x) {
                        return nextInputFn(__x, nextValue);
                      })
                  });
            }),
          blurEncryptedPassword: (function () {
              dispatch("BlurEncryptedPasswordField");
            }),
          blurUsername: (function () {
              dispatch("BlurUsernameField");
            }),
          blurAdminUrl: (function () {
              dispatch("BlurAdminUrlField");
            }),
          blurHomeUrl: (function () {
              dispatch("BlurHomeUrlField");
            }),
          blurContractStart: (function () {
              dispatch("BlurContractStartField");
            }),
          blurDescription: (function () {
              dispatch("BlurDescriptionField");
            }),
          blurActive: (function () {
              dispatch("BlurActiveField");
            }),
          blurName: (function () {
              dispatch("BlurNameField");
            }),
          encryptedPasswordResult: Formality.exposeFieldResult(state.fieldsStatuses.encryptedPassword),
          usernameResult: Formality.exposeFieldResult(state.fieldsStatuses.username),
          adminUrlResult: Formality.exposeFieldResult(state.fieldsStatuses.adminUrl),
          homeUrlResult: Formality.exposeFieldResult(state.fieldsStatuses.homeUrl),
          contractStartResult: Formality.exposeFieldResult(state.fieldsStatuses.contractStart),
          descriptionResult: Formality.exposeFieldResult(state.fieldsStatuses.description),
          activeResult: Formality.exposeFieldResult(state.fieldsStatuses.active),
          nameResult: Formality.exposeFieldResult(state.fieldsStatuses.name),
          input: state.input,
          status: state.formStatus,
          dirty: (function () {
              var match = state.fieldsStatuses;
              var tmp = match.encryptedPassword;
              if (typeof tmp === "object") {
                return true;
              }
              var tmp$1 = match.username;
              if (typeof tmp$1 === "object") {
                return true;
              }
              var tmp$2 = match.adminUrl;
              if (typeof tmp$2 === "object") {
                return true;
              }
              var tmp$3 = match.homeUrl;
              if (typeof tmp$3 === "object") {
                return true;
              }
              var tmp$4 = match.contractStart;
              if (typeof tmp$4 === "object") {
                return true;
              }
              var tmp$5 = match.description;
              if (typeof tmp$5 === "object") {
                return true;
              }
              var tmp$6 = match.active;
              if (typeof tmp$6 === "object") {
                return true;
              }
              var tmp$7 = match.name;
              if (typeof tmp$7 !== "object") {
                return false;
              } else {
                return true;
              }
            }),
          valid: (function () {
              var match = validateForm(state.input, validators, state.fieldsStatuses);
              if (match.TAG === "Valid") {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function () {
              dispatch("Submit");
            }),
          dismissSubmissionError: (function () {
              dispatch("DismissSubmissionError");
            }),
          dismissSubmissionResult: (function () {
              dispatch("DismissSubmissionResult");
            }),
          mapSubmissionError: (function (map) {
              dispatch({
                    TAG: "MapSubmissionError",
                    _0: map
                  });
            }),
          reset: (function () {
              dispatch("Reset");
            })
        };
}

var TsdEditForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

function DashboardTsdEditForm(props) {
  var tsd = props.tsd;
  var initialInput = React.useMemo((function () {
          return {
                  name: tsd.name,
                  active: tsd.active,
                  description: tsd.description,
                  contractStart: Caml_option.some(new Date(tsd.contractStart)),
                  homeUrl: tsd.homeUrl,
                  adminUrl: tsd.adminUrl,
                  username: tsd.username,
                  encryptedPassword: tsd.encryptedPassword
                };
        }), []);
  var initialState_contractResource = tsd.contractResource;
  var initialState = {
    message: undefined,
    document: undefined,
    contractResource: initialState_contractResource,
    messages: undefined,
    usernameVisible: false,
    passwordVisible: false
  };
  var match = React.useReducer(reducer, initialState);
  var dispatch = match[1];
  var state = match[0];
  var form = useForm(initialInput, (function (output, cb) {
          $$Promise.wait(Api_Tsd.Dashboard.update(tsd.id, output.name, output.active, output.description, output.contractStart, state.document, output.homeUrl, output.adminUrl, output.username, output.encryptedPassword), (function (x) {
                  if (x.TAG === "Ok") {
                    return Url.visit(Routes_Tsd.Dashboard.edit(x._0.id));
                  }
                  var match = x._0;
                  if (typeof match === "object") {
                    var errors = match.VAL;
                    SentryLogger.error1({
                          rootModule: "DashboardTsdEditForm",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "DashboardTsdEditForm.make"
                        }, "TsdEdit::Error", [
                          "Error",
                          errors
                        ]);
                    dispatch({
                          TAG: "SetMessages",
                          _0: errors.activeModelErrors
                        });
                    return cb.notifyOnFailure();
                  }
                  SentryLogger.error1({
                        rootModule: "DashboardTsdEditForm",
                        subModulePath: /* [] */0,
                        value: "make",
                        fullPath: "DashboardTsdEditForm.make"
                      }, "TsdEdit::Error", [
                        "Error",
                        "Unknown Error"
                      ]);
                  cb.notifyOnFailure();
                }));
        }));
  var fieldError = function (field) {
    if (field !== undefined && field.TAG !== "Ok") {
      return JsxRuntime.jsx(ResponseMessage.make, {
                  messageType: "Error",
                  children: field._0
                });
    } else {
      return null;
    }
  };
  var match$1 = state.document;
  var match$2 = form.status;
  var tmp;
  if (typeof match$2 !== "object" || match$2.TAG !== "SubmissionFailed") {
    tmp = null;
  } else {
    var x = state.messages;
    tmp = JsxRuntime.jsx(ResponseMessage.make, {
          messageType: "Error",
          children: x !== undefined ? JsxRuntime.jsx(ActiveModelError.List.make, {
                  errors: x
                }) : JsxRuntime.jsx("p", {
                  children: "Something went wrong."
                })
        });
  }
  return JsxRuntime.jsxs("form", {
              children: [
                JsxRuntime.jsxs(H1.make, {
                      className: DashboardFormCss.titleHeader,
                      children: [
                        JsxRuntime.jsx("span", {
                              children: "Edit",
                              className: DashboardFormCss.titleSpan
                            }),
                        tsd.name,
                        JsxRuntime.jsx("a", {
                              children: "View",
                              className: DashboardFormCss.showLink,
                              href: Routes_Tsd.Dashboard.show(tsd.id)
                            })
                      ]
                    }),
                JsxRuntime.jsx(DashboardForm.SectionTitle.make, {
                      children: "General Information"
                    }),
                JsxRuntime.jsxs(DashboardForm.FieldContainer.make, {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "tsd--name",
                              className: DashboardFormCss.required,
                              children: "Name"
                            }),
                        JsxRuntime.jsx(TextField.make, {
                              id: "tsd--name",
                              value: form.input.name,
                              placeholder: "Add a name for your new TSD.",
                              onChange: (function ($$event) {
                                  form.updateName((function (input, value) {
                                          return {
                                                  name: value,
                                                  active: input.active,
                                                  description: input.description,
                                                  contractStart: input.contractStart,
                                                  homeUrl: input.homeUrl,
                                                  adminUrl: input.adminUrl,
                                                  username: input.username,
                                                  encryptedPassword: input.encryptedPassword
                                                };
                                        }), $$event.target.value);
                                })
                            }),
                        fieldError(form.nameResult)
                      ]
                    }),
                JsxRuntime.jsxs(DashboardForm.FieldContainer.make, {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "tsd--description",
                              children: "Description"
                            }),
                        JsxRuntime.jsx(Textarea.make, {
                              id: "tsd--description",
                              value: form.input.description,
                              placeholder: "(optional) Add a description.",
                              onChange: (function ($$event) {
                                  form.updateName((function (input, value) {
                                          return {
                                                  name: input.name,
                                                  active: input.active,
                                                  description: value,
                                                  contractStart: input.contractStart,
                                                  homeUrl: input.homeUrl,
                                                  adminUrl: input.adminUrl,
                                                  username: input.username,
                                                  encryptedPassword: input.encryptedPassword
                                                };
                                        }), $$event.target.value);
                                })
                            }),
                        fieldError(form.descriptionResult)
                      ]
                    }),
                JsxRuntime.jsx(Hr.make, {}),
                JsxRuntime.jsx(DashboardForm.SectionTitle.make, {
                      children: "Website and External Administration"
                    }),
                JsxRuntime.jsxs(DashboardForm.FieldContainer.make, {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "tsd--homeUrl",
                              children: "Home Page"
                            }),
                        JsxRuntime.jsx(TextField.make, {
                              id: "tsd--homeUrl",
                              value: form.input.homeUrl,
                              placeholder: "(optional) Ex. https://www.datacenters.com/",
                              onChange: (function ($$event) {
                                  form.updateHomeUrl((function (input, value) {
                                          return {
                                                  name: input.name,
                                                  active: input.active,
                                                  description: input.description,
                                                  contractStart: input.contractStart,
                                                  homeUrl: value,
                                                  adminUrl: input.adminUrl,
                                                  username: input.username,
                                                  encryptedPassword: input.encryptedPassword
                                                };
                                        }), $$event.target.value);
                                })
                            }),
                        fieldError(form.homeUrlResult)
                      ]
                    }),
                JsxRuntime.jsxs(DashboardForm.FieldContainer.make, {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "tsd--adminUrl",
                              children: "Admin Url"
                            }),
                        JsxRuntime.jsx(TextField.make, {
                              id: "tsd--adminUrl",
                              value: form.input.adminUrl,
                              placeholder: "(optional) Ex. https://admin.datacenters.com/",
                              onChange: (function ($$event) {
                                  form.updateAdminUrl((function (input, value) {
                                          return {
                                                  name: input.name,
                                                  active: input.active,
                                                  description: input.description,
                                                  contractStart: input.contractStart,
                                                  homeUrl: input.homeUrl,
                                                  adminUrl: value,
                                                  username: input.username,
                                                  encryptedPassword: input.encryptedPassword
                                                };
                                        }), $$event.target.value);
                                })
                            }),
                        fieldError(form.adminUrlResult)
                      ]
                    }),
                JsxRuntime.jsxs(DashboardForm.MultiFieldContainer.make, {
                      children: [
                        JsxRuntime.jsxs(DashboardForm.FieldContainer25.make, {
                              children: [
                                JsxRuntime.jsxs(Label.make, {
                                      forId: "tsd--username",
                                      className: DashboardFormCss.passwordFieldLabel,
                                      children: [
                                        "Username",
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(IconView.make, {
                                                    size: "LG",
                                                    color: "DarkGray"
                                                  }),
                                              onClick: (function (param) {
                                                  dispatch("SetUsernameVisible");
                                                })
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsx(TextField.make, {
                                      id: "tsd--username",
                                      type_: state.usernameVisible ? "Text" : "Password",
                                      value: form.input.username,
                                      placeholder: "(optional) username",
                                      onChange: (function ($$event) {
                                          form.updateUsername((function (input, value) {
                                                  return {
                                                          name: input.name,
                                                          active: input.active,
                                                          description: input.description,
                                                          contractStart: input.contractStart,
                                                          homeUrl: input.homeUrl,
                                                          adminUrl: input.adminUrl,
                                                          username: value,
                                                          encryptedPassword: input.encryptedPassword
                                                        };
                                                }), $$event.target.value);
                                        })
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(DashboardForm.FieldContainer25.make, {
                              children: [
                                JsxRuntime.jsxs(Label.make, {
                                      forId: "tsd--encryptedPassword",
                                      className: DashboardFormCss.passwordFieldLabel,
                                      children: [
                                        "Password",
                                        JsxRuntime.jsx("div", {
                                              children: JsxRuntime.jsx(IconView.make, {
                                                    size: "LG",
                                                    color: "DarkGray"
                                                  }),
                                              onClick: (function (param) {
                                                  dispatch("SetPasswordVisible");
                                                })
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsx(TextField.make, {
                                      id: "tsd--encryptedPassword",
                                      type_: state.passwordVisible ? "Text" : "Password",
                                      value: form.input.encryptedPassword,
                                      placeholder: "(optional) password",
                                      onChange: (function ($$event) {
                                          form.updateEncryptedPassword((function (input, value) {
                                                  return {
                                                          name: input.name,
                                                          active: input.active,
                                                          description: input.description,
                                                          contractStart: input.contractStart,
                                                          homeUrl: input.homeUrl,
                                                          adminUrl: input.adminUrl,
                                                          username: input.username,
                                                          encryptedPassword: value
                                                        };
                                                }), $$event.target.value);
                                        })
                                    })
                              ]
                            })
                      ]
                    }),
                JsxRuntime.jsx(Hr.make, {}),
                JsxRuntime.jsx(DashboardForm.SectionTitle.make, {
                      children: "Contract Settings"
                    }),
                JsxRuntime.jsxs(DashboardForm.MultiFieldContainer.make, {
                      children: [
                        JsxRuntime.jsxs(DashboardForm.FieldContainer25.make, {
                              children: [
                                JsxRuntime.jsx(Label.make, {
                                      forId: "tsd--contractStart",
                                      className: DashboardFormCss.required,
                                      children: "Contract Start"
                                    }),
                                JsxRuntime.jsx(ReactDatepicker.TextField.make, {
                                      date: Belt_Option.getExn(form.input.contractStart),
                                      onChange: (function (date) {
                                          form.updateContractStart((function (input, value) {
                                                  return {
                                                          name: input.name,
                                                          active: input.active,
                                                          description: input.description,
                                                          contractStart: value,
                                                          homeUrl: input.homeUrl,
                                                          adminUrl: input.adminUrl,
                                                          username: input.username,
                                                          encryptedPassword: input.encryptedPassword
                                                        };
                                                }), Caml_option.some(date));
                                        })
                                    }),
                                fieldError(form.contractStartResult)
                              ]
                            }),
                        JsxRuntime.jsxs(DashboardForm.FieldContainer25.make, {
                              children: [
                                JsxRuntime.jsx(Label.make, {
                                      id: tsd.active ? "tsd--isActive" : "tsd--isInactive",
                                      forId: "tsd--active",
                                      className: DashboardFormCss.required,
                                      children: "Contract Active?"
                                    }),
                                JsxRuntime.jsx(Toggle.make, {
                                      id: "tsdActiveToggle",
                                      on: form.input.active,
                                      size: "MD",
                                      onChange: (function () {
                                          form.updateActive((function (input, value) {
                                                  return {
                                                          name: input.name,
                                                          active: value,
                                                          description: input.description,
                                                          contractStart: input.contractStart,
                                                          homeUrl: input.homeUrl,
                                                          adminUrl: input.adminUrl,
                                                          username: input.username,
                                                          encryptedPassword: input.encryptedPassword
                                                        };
                                                }), !form.input.active);
                                        })
                                    })
                              ]
                            })
                      ]
                    }),
                JsxRuntime.jsxs(DashboardForm.FieldContainer.make, {
                      children: [
                        JsxRuntime.jsx(Label.make, {
                              forId: "tsd--contract",
                              children: "Contract"
                            }),
                        match$1 !== undefined ? null : JsxRuntime.jsx(DashboardDocumentPdf.make, {
                                document: state.contractResource,
                                removeDocument: (function (param) {
                                    dispatch({
                                          TAG: "SetContractResource",
                                          _0: undefined
                                        });
                                  }),
                                privateDownload: tsd.contractDownload
                              }),
                        JsxRuntime.jsx(ResourceDocument.make, {
                              document: state.document,
                              removeDocument: (function (param) {
                                  dispatch({
                                        TAG: "SetDocument",
                                        _0: undefined
                                      });
                                })
                            }),
                        JsxRuntime.jsx(Uploader.make, {
                              allowed: ["Pdf"],
                              multiple: false,
                              upload: (function (files) {
                                  Belt_Array.map(files, (function (file) {
                                          var match = Uploader.FileType.fromMime(file.type);
                                          if (match === "Pdf") {
                                            return dispatch({
                                                        TAG: "SetDocument",
                                                        _0: Caml_option.some(file)
                                                      });
                                          }
                                          
                                        }));
                                }),
                              setUploadHandler: (function (prim) {
                                  
                                }),
                              children: JsxRuntime.jsxs(Button.make, {
                                    id: "uploadButton",
                                    size: "SM",
                                    color: "Teal",
                                    buttonClassName: DashboardFormCss.uploadDocumentButton,
                                    children: [
                                      JsxRuntime.jsx(IconAdditionalUpload.make, {
                                            size: "MD",
                                            color: "White"
                                          }),
                                      "Upload Contract"
                                    ]
                                  })
                            })
                      ]
                    }),
                tmp,
                JsxRuntime.jsxs(DashboardForm.ButtonContainer.make, {
                      children: [
                        JsxRuntime.jsx(Button.make, {
                              id: "submitButton",
                              size: "MD",
                              color: "Primary",
                              disabled: form.submitting,
                              busy: form.submitting,
                              submit: true,
                              children: "Save"
                            }),
                        JsxRuntime.jsx(Button.make, {
                              size: "MD",
                              color: "White",
                              busy: false,
                              submit: true,
                              onClick: (function (e) {
                                  e.preventDefault();
                                  Url.visit(Routes_Tsd.Dashboard.index);
                                }),
                              children: "Cancel"
                            })
                      ]
                    })
              ],
              className: DashboardFormCss.container,
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  form.submit();
                })
            });
}

var Css;

var FieldContainer;

var FieldContainer25;

var MultiFieldContainer;

var ButtonContainer;

var SectionTitle;

var make = DashboardTsdEditForm;

export {
  Css ,
  FieldContainer ,
  FieldContainer25 ,
  MultiFieldContainer ,
  ButtonContainer ,
  SectionTitle ,
  reducer ,
  TsdEditForm ,
  make ,
}
/* H1 Not a pure module */
